<template>
	<div class="real_run">
		<div :style="{ width: boxWidth }" class="box">
			<siteListCom type="0" :waitingList="upWait" :siteList="upList"></siteListCom>
			<siteListCom type="1" :waitingList="downWait" :siteList="downList" v-show="!isAnnular()"></siteListCom>
		</div>
		<div class="des-content">
			<ul>
				<li>
					<div class="mask"></div>
					<span class="maskLable">到站</span>
				</li>
				<li>
					<div class="mask sgs"></div>
					<span class="maskLable sgs">小间隔</span>
				</li>
				<li>
					<div class="mask lgs"></div>
					<span class="maskLable lgs">大间隔</span>
				</li>
				<li>
					<div class="mask stc"></div>
					<span class="maskLable stc">串车</span>
				</li>
				<li>
					<div class="mask out"></div>
					<span class="maskLable out">离线</span>
				</li>
			</ul>
			<div class="car-list">
				<div class="car-item" v-for="item in carList" :key="item.vhId" :class="item.online === 1 ? 'on-line' : ''">
					<p>{{ item.vhNo }}</p>
					<img :src="item.online === 1 ? onSite : outLineCar" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import line from "./realRunSub/line.vue";
import onSite from "@/assets/realTime/onSite.png";
import outLineCar from "@/assets/realTime/outLineCar.png";

export default {
	inject: ["siteList", "isAnnular"],
	data() {
		return {
			boxWidth: "",
			carList: [],
			onSite,
			outLineCar
		};
	},
	props: {
		formData: {
			type: Object,
			default: () => {}
		},
		waitVehicleList: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	components: { siteListCom: line },
	computed: {
		upList: function () {
			let list = this.siteList.filter(one => one.goType == 1 || one.goType == 3);
			list.sort((a, b) => {
				return a.num - b.num;
			});
			return list;
		},
		downList: function () {
			let list = this.siteList.filter(one => one.goType == 2);
			list.sort((a, b) => {
				return b.num - a.num;
			});
			return list;
		},
		upWait: function () {
			let list = this.waitVehicleList.filter(one => one.schemeT == 1 || one.schemeT == 3);
			list.sort((a, b) => {
				return Number(a.dTime.replace(":", "")) - Number(b.dTime.replace(":", ""));
			});
			console.log(list);
			return list;
		},
		downWait: function () {
			let list = this.waitVehicleList.filter(one => one.schemeT == 2);
			list.sort((a, b) => {
				return Number(a.dTime.replace(":", "")) - Number(b.dTime.replace(":", ""));
			});
			console.log(list);
			return list;
		}
	},
	watch: {
		upList() {
			console.log(this.waitVehicleList);
			this.getWidth();
		},
		formData: {
			handler() {
				this.getCarList();
			},
			immediate: true
		}
	},
	methods: {
		getWidth() {
			let length = this.upList.length;
			this.boxWidth = length * 30 + 100 + "px";
		},
		getCarList() {
			const params = {
				routeId: this.formData.routeId
			};
			const url = "/realtime/getVehicleOnlineStatusByRouteId";
			this.$http.post(url, params).then(({ detail }) => {
				console.log(detail, "-----z7kaln");
				this.carList = detail;
			});
		}
	}
};
</script>
<style lang="scss" scoped="real_run">
@import "../realTime.scss";

.real_run {
	// border: 1px solid $bordercolor;
	padding: 20px;
	overflow-x: auto;
	position: relative;
	.box {
		min-width: 100%;
	}
	.des-content {
		position: absolute;
		top: 40px;
		left: 30px;
		padding-bottom: 20px;
		display: flex;
		align-items: center;
		.car-list {
			display: flex;
			align-items: center;
			margin-left: 50px;
			flex-wrap: wrap;
			.car-item {
				margin-right: 20px;
				color: #b5b5b5;
				font-size: 16px;
				line-height: 20px;
				&:last-child {
					margin-right: 0;
				}
				&.on-line {
					color: #2ecc71;
				}
			}
		}
	}

	ul {
		/* position: absolute;
        top:40px;
        left:30px;
        padding-bottom: 20px; */
		li {
			display: inline;
			list-style-type: none;
			padding: 5px 5px;
			+ li {
				margin-top: 4px;
			}
			> * {
				vertical-align: middle;
			}

			.mask {
				display: inline-block;
				width: $site_size;
				height: $site_size;
				border-radius: 50%;
				// border: 2px solid $rbc;
				background: $rbc;
				margin-right: 4px;

				&.out {
					background: $out;
				}
				&.lgs {
					background: $lgs;
				}
				&.sgs {
					background: $sgs;
				}
				&.stc {
					background: $stc;
				}
			}
			.maskLable {
				color: $rbc;
				font-size: 18px;
				font-weight: bold;
				&.out {
					color: $out;
				}
				&.lgs {
					color: $lgs;
				}
				&.sgs {
					color: $sgs;
				}
				&.stc {
					color: $stc;
				}
			}
		}
	}
}
</style>
