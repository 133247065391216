<template>
	<div id="real_time">
		<jy-query ref="form" :model="formData" label-width="60px">
			<jy-query-item>
				<el-radio-group v-model="tabs">
					<el-radio-button label="0">站点模式</el-radio-button>
					<el-radio-button label="1">地图模式</el-radio-button>
				</el-radio-group>
			</jy-query-item>
			<jy-query-item label="线路">
				<el-select v-model="formData.selectRoute" filterable multiple collapse-tags @change="routeChange">
					<el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
				</el-select>
			</jy-query-item>
		</jy-query>
		<div class="route_content">
			<routeRealRun v-for="real in realTimeList" :key="real.routeId" :params="getParams(real)" :tab="tabs"></routeRealRun>
		</div>
	</div>
</template>
<script>
import routeRealRun from "@/components/pages/admin/realTime/routeRealRun.vue";
export default {
	computed: {
		getParams() {
			return function (real) {
				return {
					routeId: real.routeId,
					routeNa: real.routeNa,
					oDate: real.oDate,
					isAnnular: real.isAnnular
				};
			};
		}
	},
	data() {
		return {
			routeList: [],
			realTimeList: [],
			isAnnular: false, //是否环形
			tabs: "0",
			formData: {
				selectRoute: []
			}
		};
	},
	components: {
		routeRealRun
	},
	created() {
		this.getRouteList();
	},
	watch: {
		"formData.selectRoute": function (val) {
			console.log(val);
			this.realTimeList = [];
			val.forEach(e => {
				this.routeList.forEach(item => {
					debugger;
					if (e == item.routeId) {
						if (item.rType == "1") {
							this.isAnnular = true;
						} else {
							this.isAnnular = false;
						}
						this.realTimeList.push({
							routeId: item.routeId,
							routeNa: item.cName,
							isAnnular: this.isAnnular
						});
					}
				});
			});
		}
	},
	methods: {
		getRouteList() {
			let url = "/route/info/queryRouteInfoByOrgIds";
			this.$http.post(url).then(({ detail }) => {
				this.routeList = detail;
				console.log(detail);
				this.formData.selectRoute.push(detail[0].routeId);
				let list = [];
				list.push(detail[0].routeId);
				this.routeChange(list);
			});
		},
		routeChange(val) {
			console.log(val);
			this.realTimeList = [];
			val.forEach(e => {
				this.routeList.forEach(item => {
					if (e == item.routeId) {
						if (item.rType == "1") {
							this.isAnnular = true;
						} else {
							this.isAnnular = false;
						}
						this.realTimeList.push({
							routeId: item.routeId,
							routeNa: item.cName,
							oDate: new Date().Format("yyyy-MM-dd"),
							isAnnular: this.isAnnular
						});
					}
				});
			});
		}
	}
};
</script>
<style lang="scss" scoped="real_time">
.real_time {
	.route_content {
		margin-top: 10px;
	}
}
</style>
