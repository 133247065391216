import { render, staticRenderFns } from "./site.vue?vue&type=template&id=3df31580&scoped=true"
import script from "./site.vue?vue&type=script&lang=js"
export * from "./site.vue?vue&type=script&lang=js"
import style0 from "./site.vue?vue&type=style&index=0&id=3df31580&prod&lang=scss&scoped=real_time_site"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df31580",
  null
  
)

export default component.exports