<template>
	<div class="real_run_line type_down">
		<div class="line"></div>
		<div class="site_box">
			<div class="station_box" v-if="type == 0">
				<div class="station">
					<div class="vehicles">
						<div class="ve" v-for="(one, k) in setNearList" :key="k">
							<div v-if="k < 3">{{ one.customNo }}</div>
						</div>
					</div>
					<el-popover :placement="type == 0 ? 'top' : 'bottom'" trigger="hover" :disabled="waitingList.length < 1">
						<ul class="pop_ul">
							<li class="pop_li" v-for="(one, kk) in waitingList" :key="kk">
								<span>
									{{ one.customNo }}
								</span>
								<span>
									{{ one.dTime }}
								</span>
							</li>
						</ul>
						<div class="text" slot="reference">待发车</div>
					</el-popover>
				</div>
			</div>
			<site
				:goType="type"
				:type="mytype"
				v-for="(one, key) in siteList"
				:num="getSiteNum(key)"
				:key="one.cName"
				:siteName="one.cName"
				:vehicleList="getSiteVehicleList(one)"
				:largeIntervalDetailList="getLargeIntervalDetailList(one)"
				:stringCarDetail="getStringCarDetail(one)"
			></site>
			<div class="station_box" v-if="type == 1">
				<div class="station">
					<div class="vehicles">
						<div class="ve" v-for="(one, k) in setNearList" :key="k">
							<div v-if="k < 3">{{ one.customNo }}</div>
						</div>
					</div>
					<el-popover :placement="type == 0 ? 'top' : 'bottom'" trigger="hover" :disabled="waitingList.length < 1">
						<ul class="pop_ul">
							<li class="pop_li" v-for="(one, kk) in waitingList" :key="kk">
								<span>
									{{ one.customNo }}
								</span>
								<span>
									{{ one.dTime }}
								</span>
							</li>
						</ul>
						<div class="text" slot="reference">待发车</div>
					</el-popover>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import site from "./site.vue";
import Util from "@/common/js/util.js";
export default {
	inject: ["positionVehicleList", "largeIntervalDetailList", "stringCarDetail"],
	data() {
		return {
			mytype: "1"
		};
	},
	props: {
		type: {
			type: String,
			default: "0"
		},
		siteList: {
			type: Array,
			default() {
				return [];
			}
		},
		waitingList: {
			type: Array,
			default() {
				return [];
			}
		}
	},
	components: {
		site
	},
	computed: {
		getSiteVehicleList: function () {
			return site => {
				let list = this.positionVehicleList.filter(one => one.siteId == site.siteId);
				return list;
			};
		},
		getLargeIntervalDetailList: function () {
			return site => {
				let list = this.largeIntervalDetailList.filter(one => one.routeId == site.routeId);
				return list;
			};
		},
		getStringCarDetail: function () {
			return site => {
				let list = this.stringCarDetail.filter(one => one.routeId == site.routeId);
				return list;
			};
		},
		setNearList: function () {
			let nowTime = new Date().Format("hh:mm");
			let greaterList = this.waitingList.filter(one => Number(one.dTime.replace(":", "")) >= Number(nowTime.replace(":", "")));
			let lessList = this.waitingList.filter(one => Number(one.dTime.replace(":", "")) < Number(nowTime.replace(":", "")));
			lessList.sort((a, b) => {
				return Number(b.dTime.replace(":", "")) - Number(a.dTime.replace(":", ""));
			});
			return greaterList.concat(lessList);
		}
	},
	filters: {
		vehicleNoFilter: val => {
			if (Util.isChinese(val)) {
				return val.substr(1);
			}
			return val;
		}
	},
	mounted() {
		console.log(this.positionVehicleList);
		console.log(this.siteList);
	},
	methods: {
		getSiteNum(k) {
			if (this.type == 0) {
				return k + 1;
			}
			return this.siteList.length - k;
		}
	}
};
</script>
<style lang="scss" scoped="real_run_line">
@import "../../realTime.scss";
$dis: 68px;
$sizedt: $site_size / 2;

.real_run_line {
	position: relative;
	height: 222px;
	margin-top: 50px;

	.line {
		height: 3px;
		width: 100%;
		// background: #4e95ef;
		background: #e6e6e6;
		position: absolute;
		z-index: -1;
		bottom: $dis;
	}

	.site_box {
		display: flex;
		justify-content: space-between;

		.station_box {
			width: 48px;
		}

		.station {
			line-height: $site_size - 4px;
			padding: 0 4px;
			height: $site_size;
			border-radius: $site_size / 2;
			// border: 2px solid #4e95ef;
			border: 2px solid #e6e6e6;
			background: #b5b5b5;
			position: absolute;
			bottom: $dis - $sizedt;

			.text {
				color: #fff;
				@extend .hand;
			}

			.vehicles {
				position: absolute;
				bottom: $site_size;
				left: 50%;
				transform: translateX(-50%);
				.ve {
					white-space: nowrap;
				}
			}
		}
	}

	&.type_down {
		.line {
			top: $dis;
		}

		.station {
			top: $dis - $sizedt;

			.vehicles {
				top: $site_size;
			}
		}
	}
}
</style>
